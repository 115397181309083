<template>
  <transition name="lang">
    <div v-if="langOpen" class="drop-lang">
      <div class="lang-item" @click="setLocale('de')">
        <p class="lang-name-desktop">Deutsch</p>
        <p class="lang-name-mobile">DE</p>
      </div>
      <div class="lang-item" @click="setLocale('en')">
        <p class="lang-name-desktop">English</p>
        <p class="lang-name-mobile">EN</p>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "LangSelection",
  props: {
    langOpen: { type: Boolean, require: true, default: false },
  },
  methods: {
    setLocale(locale) {
      this.$emit("close");
      if (locale == this.$i18n.locale) return;
      this.$i18n.locale = locale;
      this.$router.push({
        params: { lang: locale },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/components/lang-selection.scss";
</style>
