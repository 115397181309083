import Vue from "vue";
import VueRouter from "vue-router";
import i18n from "../i18n";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: `/${i18n.locale}`,
  },
  {
    path: "/:lang",
    component: {
      render(c) {
        return c("router-view");
      },
    },
    children: [
      {
        path: "/",
        name: "Home",
        component: () => import("../views/Home.vue"),
      },
      {
        path: "office",
        name: "Office",
        component: () => import("../views/Office.vue"),
      },
      {
        path: "contact",
        name: "Contact",
        component: () => import("../views/Contact.vue"),
      },
      {
        path: "projects",
        name: "Projects",
        component: () => import("../views/Projects.vue"),
      },
      {
        path: "project/:projectId/:projectName",
        name: "Project",
        component: () => import("../views/Project.vue"),
      },
      {
        path: "imprint",
        name: "Imprint",
        component: () => import("../views/Imprint.vue"),
      },
      {
        path: "privacy-policy",
        name: "Privacy",
        component: () => import("../views/Privacy.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return {
        x: 0,
        y: 0,
      };
    }
  },
  routes,
});

export default router;
