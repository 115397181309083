<template>
  <nav
    id="nav"
    :class="[
      [navOpen || langOpen ? 'drop-open' : 'drop-closed'],
      [isScrolled ? 'nav-scroll' : 'nav-top'],
    ]"
  >
    <div
      v-if="navOpen || langOpen"
      class="overlay"
      @click="(navOpen = false), (langOpen = false)"
    ></div>
    <div class="nav-content">
      <div class="nav-logo">
        <router-link
          :to="`/${$i18n.locale}`"
          class="logo-link"
          @click.native="navOpen = false"
        >
          <img class="logo-image" src="@/assets/logo.jpg" alt="LOGO" />
          <h1 class="logo-title">Dirk Hansen Architekt</h1>
        </router-link>
      </div>
      <div class="nav-items">
        <div
          class="drop-button"
          :class="[navOpen ? 'drop-active' : 'drop-inactive']"
          @click="navOpen = !navOpen"
        >
          <MenuIcon :size="24" />
        </div>
        <router-link
          v-for="link in navLinks"
          :key="link"
          :to="`/${$i18n.locale}/` + link"
          class="nav-link"
        >
          {{ $t(`nav.${link}`) }}
        </router-link>
        <div class="language-selection">
          <div class="current-lang" @click="langOpen = !langOpen">
            <p>{{ $i18n.locale.toUpperCase() }}</p>
            <ChevronDown
              :size="16"
              :class="[langOpen ? 'lang-open' : 'lang-closed']"
            />
          </div>
          <lang-selection :lang-open="langOpen" @close="langOpen = false" />
        </div>
      </div>
      <transition name="drop">
        <div v-if="navOpen" class="drop-nav">
          <router-link
            v-for="link in navLinks"
            :key="link"
            :to="`/${$i18n.locale}/` + link"
            class="nav-link"
            @click.native="navOpen = false"
          >
            {{ $t(`nav.${link}`) }}
          </router-link>
          <span class="drop-divide" />
          <lang-selection :lang-open="true" @close="navOpen = false" />
        </div>
      </transition>
    </div>
  </nav>
</template>

<script>
import { ChevronDown, MenuIcon } from "lucide-vue";

import LangSelection from "./LangSelection.vue";

export default {
  name: "TheNavigation",
  components: {
    LangSelection,
    MenuIcon,
    ChevronDown,
  },
  data() {
    return {
      navOpen: false,
      langOpen: false,
      isScrolled: false,
      navLinks: ["projects", "office", "contact"],
    };
  },
  created() {
    window.addEventListener("scroll", this.navScroll);
  },
  methods: {
    navScroll() {
      if (window.scrollY !== 0) {
        this.isScrolled = true;
      } else {
        this.isScrolled = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/layout/navigation.scss";
</style>
