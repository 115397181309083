<template>
  <footer id="footer">
    <div class="footer-content">
      <div class="footer-title">
        <h1>Dirk Hansen</h1>
        <h2>Dipl.-Ing. Architekt</h2>
      </div>
      <div class="footer-info">
        <ul class="footer-list">
          <li class="footer-item">
            <p>Loehrsweg 12</p>
            <p>20249 Hamburg</p>
          </li>
          <li class="footer-item">
            <a href="tel:+49404808691">(+49) 40 / 480 86 91</a>
            <a href="mailto:post@architekt-hansen.de">
              post@architekt-hansen.de
            </a>
          </li>
          <li class="footer-item">
            <router-link :to="`/${$i18n.locale}/privacy-policy`">{{
              $t("footer.privacy")
            }}</router-link>
            <router-link :to="`/${$i18n.locale}/imprint`">{{
              $t("footer.imprint")
            }}</router-link>
          </li>
        </ul>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "TheFooter",
};
</script>

<style lang="scss" scoped>
@import "../scss/layout/footer.scss";
</style>
