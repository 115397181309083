<template>
  <div id="app">
    <header class="header">
      <the-navigation />
    </header>
    <router-view />
    <the-footer />
  </div>
</template>

<script>
import TheNavigation from "@/components/TheNavigation.vue";
import TheFooter from "@/components/TheFooter.vue";

export default {
  name: "App",
  components: {
    TheNavigation,
    TheFooter,
  },
};
</script>

<style lang="scss">
@import "./scss/base/reset.scss";
</style>
